import { post } from "./api";
//考勤汇总设置
export const getAttendanceSet = (params) => post('/v1/attendance/total/set', { request: params });
//考勤跳转
export const getjumpList = (params) => post('/v1/attendance/jumpl/list', { request: params });
//考情汇总列表
export const getAttendanceList = (params) => post('/v1/attendance/total/list', { request: params });
//打卡时间列表
export const getClocktimeList = (params) => post('/v1/attendance/clocktime/list', { request: params });
//原始记录数据列表
export const getOriginalList = (params) => post('/v1/attendance/original/list', { request: params });
//进入钉钉平台
export const goDingDing = (params) => post('/v1/attendance/dingding', { request: params });
//考勤汇总（正常/异常考勤详情）
export const normalAttendance = (params) => post('/v1/attendance/normal', { request: params });
//考勤汇总跳转（旷工详情）
export const absenteeismAttendance = (params) => post('/v1/attendance/absenteeism', { request: params });
//考勤汇总跳转（下班缺卡详情）
export const lackofCardPmAttendance = (params) => post('/v1/attendance/lackofCardPm', { request: params });
//考勤汇总跳转（上班缺卡详情）
export const lackofCardAmAttendance = (params) => post('/v1/attendance/lackofCardAm', { request: params });
//考勤汇总跳转（早退次数/时长详情）
export const leavedaysAttendance = (params) => post('/v1/attendance/leavedays', { request: params });
//考勤汇总跳转（迟到次数/时长详情）
export const latedaysAttendance = (params) => post('/v1/attendance/latedays', { request: params });
//修改打卡结果
export const attendanceUpdate = (params) => post('/v1/attendance/update', { request: params });